import React from "react"
import Helmet from "react-helmet"

import { makeStyles } from "@material-ui/core/styles"
import Spinner from "@material-ui/core/CircularProgress"

import Logo from "../assets/img/logo.png"
import Typography from "@material-ui/core/Typography"

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100vw",
    height: "100vh",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "30vw",
    maxWidth: 300,
  },
  text: {
    color: "#fff",
    marginBottom: 5,
    whiteSpace: "pre-line",
  },
}))
const Loading = ({ text }) => {
  const classes = useStyles()
  return (
    <>
      <Helmet>
        <style>
          {`
            body {
              background: #0080DD;
            }
            `}
        </style>
      </Helmet>
      <div className={classes.paper}>
        <img src={Logo} className={classes.avatar} alt="LAPP" />
        {text && (
          <Typography
            variant="caption"
            align="center"
            className={classes.text}
            component="p"
          >
            {text}
          </Typography>
        )}
        <Spinner color="secondary" />
      </div>
    </>
  )
}

export default Loading
