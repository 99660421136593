import React from "react"
import { ApolloClient } from "apollo-client"
import { InMemoryCache } from "apollo-cache-inmemory"
import { HttpLink } from "apollo-link-http"
import { ApolloLink } from "apollo-link"
import { ApolloProvider } from "@apollo/react-hooks"
export const APOLLO_CONFIG = (() => {
  let token = null
  return {
    setToken: newToken => {
      token = newToken
    },
    clearToken: () => {
      token = null
    },
    getToken: () => token,
  }
})()

const httpLink = new HttpLink({
  uri: `${"https://api.lapp.advancedlapp.com"}`,
})
const middlewareAuthLink = new ApolloLink((operation, forward) => {
  const authorizationHeader = APOLLO_CONFIG.getToken()
  if (authorizationHeader)
    operation.setContext({
      headers: {
        authorization: `Bearer ${authorizationHeader}`,
      },
    })
  return forward(operation)
})

const client = new ApolloClient({
  timeout: 1000000,
  link: ApolloLink.from([middlewareAuthLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
})

const Provider = ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
)
export default Provider
