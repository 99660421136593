import React from "react"
import DashboardElement from "../components/DashboardElement"
import Typography from "@material-ui/core/Typography"

const GiantNumber = ({
  number,
  classes,
  title,
  bgColor,
  color,
  onClick,
  ...props
}) => (
  <DashboardElement title={title}>
    <div
      className={classes.giantNumberBg2}
      style={{ background: bgColor, cursor: onClick ? "pointer" : "inherit" }}
      onClick={e => onClick && onClick(e)}
      {...props}
    >
      <Typography className={classes.giantNumber} style={{ color }}>
        {number}
      </Typography>
    </div>
  </DashboardElement>
)

export default GiantNumber
