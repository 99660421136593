import React from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"

import { SnackbarProvider } from "notistack"
import ApolloProvider from "./apollo.services"
import MaterialThemeProvider from "./material.services"

import Loading from "./screens/Loading"
import Login from "./screens/Login"
import UserList from "./screens/UserList"
import Forbidden from "./screens/Forbidden"

const VIEWER_INFO = gql`
  query viewerInfo {
    viewerInfo {
      id
      email
      role {
        id
        name
      }
    }
  }
`
function App() {
  const { data, loading } = useQuery(VIEWER_INFO)
  if (loading) return <Loading />
  if (!data) return <Login />
  const {
    viewerInfo: {
      role: { name },
    },
  } = data
  if (["superadmin", "approvedAdmins"].includes(name)) return <UserList />
  return <Forbidden />
}

const RootApp = () => (
  <MaterialThemeProvider>
    <SnackbarProvider maxSnack={3}>
      <ApolloProvider>
        <App />
      </ApolloProvider>
    </SnackbarProvider>
  </MaterialThemeProvider>
)

export default RootApp
