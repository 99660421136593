import React, { useState, useEffect } from "react"
import { useQuery, useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import { CSVLink } from "react-csv"

import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import Checkbox from "@material-ui/core/Checkbox"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import AppBar from "@material-ui/core/AppBar"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import DownloadIcon from "@material-ui/icons/CloudDownload"
import Dashboard from "./Dashboard"
import Logo from "../assets/img/logo.png"
import Loading from "./Loading"
import TableContainer from "@material-ui/core/TableContainer"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 130,
    display: "flex",
    flexDirection: "column",
  },
  logo: {
    margin: theme.spacing(2),
    width: 100,
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}))

const UserList = () => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useState(0)
  const [withExam, setWithExam] = useState(true)
  const [selectedEstablishment, setSelectedEstablishment] = useState("")

  const { data: { levels = [] } = {}, loading } = useQuery(gql`
    query {
      levels {
        id
        title
        approvedUsers {
          id
          name
          lastname
          email
          institution
          dni
          yearsOfResidence
          specialty
          establishment {
            id
            name
          }
        }
        usersForExam {
          id
          name
          lastname
          email
          institution
          dni
          yearsOfResidence
          specialty
          establishment {
            id
            name
          }
        }
      }
    }
  `)
  const UpdateUserMutation = gql`
    mutation updateUser($id: ID!, $logisticStatus: Int) {
      updateUser(id: $id, logisticStatus: $logisticStatus) {
        id
      }
    }
  `
  const [updateUser, { data: updateUserData }] = useMutation(UpdateUserMutation)

  const {
    data: { establishments = [] } = {},
    loadingEstablishments,
  } = useQuery(gql`
    query {
      establishments {
        id
        name
      }
    }
  `)
  const {
    data: { seatsByEstablishment = [] } = {},
    loadingSeatsByEstablishment,
  } = useQuery(gql`
    query {
      seatsByEstablishment {
        establishment {
          id
          name
        }
        count
        basic
        basicFree
        basicPurchased
        advanced
        advancedFree
        advancedPurchased
      }
    }
  `)
  const { data: activeInactiveStudents, loading: loadingActive } = useQuery(gql`
    query {
      activeInactiveStudents {
        activeUsers {
          id
          name
          lastname
          email
          institution
          dni
          yearsOfResidence
          specialty
          establishment {
            id
            name
          }
        }
      }
    }
  `)
  // examAttempts(where: {approved: true}) {
  const { data: examAttempts, loading: loadingExamAttempts } = useQuery(gql`
    query {
      examAttempts {
        id
        approved
        createdAt
        user {
          id
        }
      }
    }
  `)
  const { data: startOfLevel, loading: loadingStartOfLevel } = useQuery(gql`
    query {
      startOfLevel
    }
  `)
  const { data: dataSeatsByUser, loading: loadingSeatsByUser } = useQuery(gql`
    query {
      seatsByUser
    }
  `)
  const { data: userNotes, loading: loadingUserNotes } = useQuery(gql`
    query {
      userNotes
    }
  `)
  useEffect(() => {
    let isSyncingLeftScroll = false
    let isSyncingRightScroll = false
    const leftDiv = document.getElementById("left")
    const rightDiv = document.getElementById("right")
    if (!leftDiv || !rightDiv) return
    leftDiv.onscroll = function() {
      if (!isSyncingLeftScroll) {
        isSyncingRightScroll = true
        rightDiv.scrollTop = this.scrollTop
      }
      isSyncingLeftScroll = false
    }

    rightDiv.onscroll = function() {
      if (!isSyncingRightScroll) {
        isSyncingLeftScroll = true
        leftDiv.scrollTop = this.scrollTop
      }
      isSyncingRightScroll = false
    }
  }, [
    selectedTab,
    loading,
    loadingActive,
    loadingStartOfLevel,
    loadingSeatsByEstablishment,
    loadingUserNotes,
    loadingSeatsByUser,
    loadingEstablishments,
  ])
  if (
    loading ||
    loadingActive ||
    loadingStartOfLevel ||
    loadingSeatsByEstablishment ||
    loadingUserNotes ||
    loadingSeatsByUser ||
    loadingEstablishments ||
    loadingExamAttempts
  )
    return (
      <Loading
        text={
          "Cargando lista de usuarios\nLa sesión es temporal, al finalizar solo cierre la pestaña"
        }
      />
    )
  let users = []
  if (levels.length && (selectedTab == 0 || selectedTab == 1)) {
    if (!withExam) {
      let ids = [
        ...levels[0].usersForExam,
        ...levels[1].usersForExam,
        ...levels[0].approvedUsers,
        ...levels[1].approvedUsers,
      ].map(doc => doc.id)
      users = [...levels[0].usersForExam, ...levels[1].usersForExam].filter(
        (doc, index) => !ids.includes(doc.id, index + 1),
      )
    } else {
      let ids = [...levels[0].approvedUsers, ...levels[1].approvedUsers].map(
        doc => doc.id,
      )
      users = [...levels[0].approvedUsers, ...levels[1].approvedUsers].filter(
        (doc, index) => !ids.includes(doc.id, index + 1),
      )
    }
  }
  users = users.sort((a, b) => {
    const userA = examAttempts.examAttempts.find(
      attemps => attemps.user.id == a.id,
    )
    const userB = examAttempts.examAttempts.find(
      attemps => attemps.user.id == b.id,
    )
    if (userA && userB) {
      return new Date(userB.createdAt) - new Date(userA.createdAt)
    }
    return 0
  })
  const getNote = (id, levelId) => {
    const notes = {
      "0": 1,
      "1": 1.2,
      "2": 1.3,
      "3": 1.5,
      "4": 1.7,
      "5": 1.8,
      "6": 2,
      "7": 2.2,
      "8": 2.3,
      "9": 2.5,
      "10": 2.7,
      "11": 2.8,
      "12": 3,
      "13": 3.2,
      "14": 3.3,
      "15": 3.5,
      "16": 3.7,
      "17": 3.8,
      "18": 4,
      "19": 4.2,
      "20": 4.3,
      "21": 4.5,
      "22": 4.7,
      "23": 4.8,
      "24": 5,
      "25": 5.1,
      "26": 5.3,
      "27": 5.4,
      "28": 5.5,
      "29": 5.6,
      "30": 5.8,
      "31": 5.9,
      "32": 6,
      "33": 6.1,
      "34": 6.3,
      "35": 6.4,
      "36": 6.5,
      "37": 6.6,
      "38": 6.8,
      "39": 6.9,
      "40": 7,
    }
    if (userNotes && userNotes.userNotes[levelId]) {
      const note = userNotes.userNotes[levelId][id]
      if (note) {
        return {
          total: (7 * 0.8 + notes[note.note] * 0.2).toFixed(1),
          partial: notes[note.note],
        }
      } else {
        return null
      }
    } else {
      return null
    }
  }
  const getStart = (id, levelId) => {
    if (startOfLevel) {
      if (levelId == 1) {
        return `${
          startOfLevel.startOfLevel[id]["1"].start
            ? startOfLevel.startOfLevel[id]["1"].start
            : ""
        }`
      }
      if (levelId == 2) {
        return `${
          startOfLevel.startOfLevel[id]["2"].start
            ? startOfLevel.startOfLevel[id]["2"].start
            : ""
        }`
      }
      return null
    } else {
      return null
    }
  }
  const getEnd = (id, levelId) => {
    if (startOfLevel) {
      if (levelId == 1) {
        return `${
          startOfLevel.startOfLevel[id]["1"].end
            ? startOfLevel.startOfLevel[id]["1"].end
            : ""
        }`
      }
      if (levelId == 2) {
        return `${
          startOfLevel.startOfLevel[id]["2"].end
            ? startOfLevel.startOfLevel[id]["2"].end
            : ""
        }`
      }
      return null
    } else {
      return null
    }
  }
  const getSeatTypes = (id, levelId) => {
    const seats = dataSeatsByUser.seatsByUser[id]
    let types = ""
    seats.forEach(seat => {
      if (!types && levelId == seat.levelId) {
        if (seat.seatType == "initial") {
          types += "No Pago"
        } else if (seat.seatType == "purchased") {
          types += "Pago"
        }
      }
    })
    return types
  }
  const getStatus = id => {
    const end = getEnd(id, 2)
    if (!end) {
      const user = activeInactiveStudents.activeInactiveStudents.activeUsers.find(
        item => item.id == id,
      )
      if (user) {
        return "Activo"
      } else {
        return "Inactivo"
      }
    } else {
      return "Finalizado"
    }
  }
  const updateUserStatus = async (id, status, index) => {
    try {
      await updateUser({
        variables: {
          id,
          logisticStatus: status,
        },
      })
      users[index].logisticStatus = status
    } catch (err) {
      console.log(err)
    }
  }
  const csvData = [
    [
      "Nombre",
      "Institución",
      "Email",
      "DNI",
      "Años de Residencia",
      "Especialidad",
      "Establecimiento LAPP",
      "Status",
      "Nota Practica | Basico",
      "Nota Teorica | Basico",
      "Nota Final | Basico",
      "Fecha de inicio | Basico",
      "Fecha de finalizacion | Basico",
      "Licencia | Basico",
      "Nota Practica | Avanzado",
      "Nota Teorica | Avanzado",
      "Nota Final | Avanzado",
      "Fecha de inicio | Avanzado",
      "Fecha de finalizacion | Avanzado",
      "Licencia | Avanzado",
    ],
    ...users
      .filter(val => {
        if (
          selectedEstablishment &&
          val.establishment.id == selectedEstablishment
        ) {
          return true
        } else if (!selectedEstablishment) {
          return true
        } else {
          return false
        }
      })
      .map(user => [
        `${user.name} ${user.lastname}`,
        user.institution,
        user.email,
        user.dni,
        user.yearsOfResidence > 0 ? user.yearsOfResidence : "",
        user.specialty,
        user.establishment.name,
        getStatus(user.id),
        getStart(user.id, 1) ? 7 : "",
        getNote(user.id, 1)?.partial,
        getNote(user.id, 1)?.total,
        getStart(user.id, 1),
        getEnd(user.id, 1),
        getSeatTypes(user.id, 1),
        getStart(user.id, 2) ? 7 : "",
        getNote(user.id, 2)?.partial,
        getNote(user.id, 2)?.total,
        getStart(user.id, 2),
        getEnd(user.id, 2),
        getSeatTypes(user.id, 2),
      ]),
  ]
  return (
    <>
      <AppBar position="fixed">
        <div className={classes.logoContainer}>
          <img src={Logo} alt="LAPP" className={classes.logo} />
          <Typography variant="h4"> · </Typography>
          <Typography variant="h4" align="center">
            Estudiantes
          </Typography>
          <Select
            style={{ marginLeft: 20 }}
            placeholder="Selecciona un centro"
            value={selectedEstablishment}
            onChange={event => {
              setSelectedEstablishment(event.target.value)
            }}
          >
            <MenuItem value="">Ver todos</MenuItem>
            {establishments.map(establishment => {
              return (
                <MenuItem key={establishment.id} value={establishment.id}>
                  {establishment.name}
                </MenuItem>
              )
            })}
          </Select>
        </div>
        <Tabs
          value={selectedTab}
          onChange={(_, value) => setSelectedTab(value)}
        >
          <Tab label="Aprobados"></Tab>
          <Tab label="Informacion"></Tab>
        </Tabs>
      </AppBar>
      <Container className={classes.container}>
        {selectedTab == 0 && (
          <div>
            <FormControlLabel
              control={
                <Switch
                  checked={withExam}
                  onChange={ev => {
                    setWithExam(ev.target.checked)
                  }}
                />
              }
              label="Con examen"
            />
          </div>
        )}
        {selectedTab < 1 && (
          <div style={{ display: "flex" }}>
            <TableContainer
              id="left"
              style={{ width: "40%", maxHeight: 600, minWidth: 350 }}
            >
              <Table
                size="small"
                stickyHeader
                style={{
                  borderRight: "2px solid #0480dd",
                }}
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={{ height: 109, width: 100 }}>
                      Nombre
                    </TableCell>
                    <TableCell>Institución</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map(user => {
                    if (
                      selectedEstablishment &&
                      user.establishment.id != selectedEstablishment
                    )
                      return null
                    // if (withExam && (!getNote(user.id, 1) && !getNote(user.id, 2))) return
                    return (
                      <TableRow style={{ height: 112 }} key={user.id}>
                        <TableCell component="th" scope="user">
                          {user.name} {user.lastname}
                        </TableCell>
                        <TableCell>{user.institution}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TableContainer id="right" style={{ maxHeight: 600 }}>
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ height: 109 }}>Email</TableCell>
                    <TableCell>DNI</TableCell>
                    <TableCell>Años de Residencia</TableCell>
                    <TableCell>Especialidad</TableCell>
                    <TableCell>Establecimiento LAPP</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Nota practica | Basico</TableCell>
                    <TableCell>Nota teorica | Basico</TableCell>
                    <TableCell>Nota final | Basico</TableCell>
                    <TableCell>Fechas de inicio | Basico</TableCell>
                    <TableCell>Fechas de finalizacion | Basico</TableCell>
                    <TableCell>Licencia | Basico</TableCell>
                    <TableCell>Nota practica | Avanzado</TableCell>
                    <TableCell>Nota teorica | Avanzado</TableCell>
                    <TableCell>Nota final | Avanzado</TableCell>
                    <TableCell>Fechas de inicio | Avanzado</TableCell>
                    <TableCell>Fechas de finalizacion | Avanzado</TableCell>
                    <TableCell>Licencia | Avanzado</TableCell>
                    <TableCell>Estatus de proceso</TableCell>
                    <TableCell>
                      <CSVLink
                        data={csvData}
                        filename={`${new Date().toISOString()}.usuarios.aprobados.csv`}
                      >
                        <DownloadIcon color="primary" />
                      </CSVLink>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user, index) => {
                    if (
                      selectedEstablishment &&
                      user.establishment.id != selectedEstablishment
                    )
                      return null
                    // if (withExam && (!getNote(user.id, 1) && !getNote(user.id, 2))) return
                    return (
                      <TableRow key={user.id} style={{ height: 112 }}>
                        <TableCell>{user.email}</TableCell>
                        <TableCell>{user.dni}</TableCell>

                        <TableCell>
                          {user.yearsOfResidence > 0
                            ? user.yearsOfResidence
                            : ""}
                        </TableCell>

                        <TableCell>{user.specialty}</TableCell>
                        <TableCell>{user.establishment.name}</TableCell>
                        <TableCell>{getStatus(user.id)}</TableCell>
                        <TableCell>{getStart(user.id, 1) ? 7 : ""}</TableCell>
                        <TableCell>{getNote(user.id, 1)?.partial}</TableCell>
                        <TableCell>{getNote(user.id, 1)?.total}</TableCell>
                        <TableCell>{getStart(user.id, 1)}</TableCell>
                        <TableCell>{getEnd(user.id, 1)}</TableCell>
                        <TableCell>
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: getSeatTypes(user.id, 1),
                            }}
                          ></div>
                        </TableCell>
                        <TableCell>{getStart(user.id, 2) ? 7 : ""}</TableCell>
                        <TableCell>{getNote(user.id, 2)?.partial}</TableCell>
                        <TableCell>{getNote(user.id, 2)?.total}</TableCell>
                        <TableCell>{getStart(user.id, 2)}</TableCell>
                        <TableCell>{getEnd(user.id, 2)}</TableCell>
                        <TableCell>
                          <div>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={user.logisticStatus == 2}
                                  onChange={() => {
                                    updateUserStatus(
                                      user.id,
                                      user.logisticStatus == 2 ? 1 : 2,
                                      index,
                                    )
                                  }}
                                  color="primary"
                                />
                              }
                              label={
                                user.logisticStatus == 2
                                  ? "Enviado"
                                  : "En proceso"
                              }
                            />
                          </div>
                        </TableCell>
                        <TableCell>
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: getSeatTypes(user.id, 2),
                            }}
                          ></div>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {selectedTab == 1 && (
          <Dashboard
            establishment={selectedEstablishment}
            seatsEstablishments={seatsByEstablishment}
          />
        )}
      </Container>
    </>
  )
}

export default UserList
