import React from "react"
import CssBaseline from "@material-ui/core/CssBaseline"

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#0080DD",
      main: "#0080DD",
      dark: "#0080DD",
    },
    secondary: {
      light: "#ffc400",
      main: "#ffc400",
      dark: "#ffc400",
    },
  },
})
const MaterialThemeProvider = ({ children }) => {
  return (
    <div>
      <CssBaseline />
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </div>
  )
}

export default MaterialThemeProvider
