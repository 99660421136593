import React, { Component } from "react"
import { withStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Popover from "@material-ui/core/Popover"
import InfoIcon from "@material-ui/icons/Info"
import FilterListIcon from "@material-ui/icons/FilterList"
import Menu from "@material-ui/core/Menu"
import IconButton from "@material-ui/core/IconButton"
import MenuItem from "@material-ui/core/MenuItem"

class DashboardElement extends Component {
  state = {
    infoPopoverEl: null,
    filterAnchorEl: null,
  }

  handleOpenPopover = event => {
    this.setState({
      infoPopoverEl: event.currentTarget,
    })
  }

  handleClosePopover = () => {
    this.setState({
      infoPopoverEl: null,
    })
  }

  handleFilterClose = () => {
    this.setState({
      filterAnchorEl: null,
    })
  }

  handleFilterClick = event => {
    this.setState({
      filterAnchorEl: event.currentTarget,
    })
  }

  handleFilterChange = f => {
    this.handleFilterClose()
    this.props.handleFilterChange(f)
  }

  render() {
    const {
      classes,
      title,
      info,
      handleFilterChange,
      filterOptions,
    } = this.props

    return (
      <div>
        <div className={classes.card}>
          <Typography variant="h5" component="h2">
            {info && (
              <IconButton
                onClick={this.handleOpenPopover}
                className={classes.info}
              >
                <InfoIcon />
              </IconButton>
            )}
            {title}
          </Typography>
          {handleFilterChange && (
            <div>
              <IconButton
                className={classes.filter}
                onClick={e => this.handleFilterClick(e)}
              >
                <FilterListIcon />
              </IconButton>

              <Menu
                id="filter-menu"
                anchorEl={this.state.filterAnchorEl}
                open={Boolean(this.state.filterAnchorEl)}
                onClose={this.handleFilterClose}
              >
                {filterOptions.map((f, i) => {
                  return (
                    <MenuItem
                      onClick={() => this.handleFilterChange(f)}
                      key={i}
                    >
                      {f.name}
                    </MenuItem>
                  )
                })}
              </Menu>
            </div>
          )}

          {info && (
            <div>
              <Popover
                open={Boolean(this.state.infoPopoverEl)}
                anchorEl={this.state.infoPopoverEl}
                onClose={this.handleClosePopover}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <div className={classes.popover}>
                  <Typography className={classes.typography}>{info}</Typography>
                </div>
              </Popover>
            </div>
          )}
          {this.props.children}
        </div>
      </div>
    )
  }
}

const styles = theme => {
  return {
    popover: {
      //padding:'20px',
      //maxWidth:'50%',
      //height:'200px',
      width: "400px",
      //transform: 'translate(50%,0)',
      padding: "30px",
    },
    card: {
      textAlign: "center",
      position: "relative",
      padding: "20px",
      // display: "flex",
      // flexFlow: "column nowrap",
      // justifyContent: "center",
      // alignItems: "center",
    },
    info: {
      color: "#727272",
      verticalAlign: "sub",
      //position: 'absolute',
      //top: '0',
      //right: '0',
      //margin: '10px',
      transform: "scale(0.9)",
    },
    filter: {
      position: "absolute",
      top: "0",
      right: "0",
    },
    giantNumber: {
      fontSize: "70px",
      fontWeight: "bold",
      lineHeight: "120px",
      textAlign: "center",
    },
    giantNumberBg: {
      margin: "20px auto",
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      width: "120px",
      height: "120px",
    },
    giantNumberBg2: {
      margin: "20px auto",
      backgroundColor: theme.palette.secondary.main,
      color: "#212121",
      borderRadius: "50%",
      width: "120px",
      height: "120px",
    },
    giantNumberSmall: {
      fontSize: "60px",
      color: "#212121",
      fontWeight: "bold",
      lineHeight: "100px",
      textAlign: "center",
    },
    giantNumberBgSmall: {
      margin: "20px auto",
      //backgroundColor:'#ffc402',
      borderRadius: "50%",
      width: "100px",
      height: "100px",
    },
  }
}

export default withStyles(styles)(DashboardElement)
