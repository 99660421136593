import React from "react"
import DashboardElement from "../components/DashboardElement"
import { ResponsiveContainer, PieChart, Tooltip, Pie, Cell } from "recharts"
import Typography from "@material-ui/core/Typography"

const EstablishmentData = ({
  establishmentFilterOptions,
  establishmentData,
  colors,
  classes,
  title,
  handleEstablishmentFilterChange,
  dataKey,
}) => (
  <DashboardElement
    title={"Alumnos por centro"}
    info={""}
    handleFilterChange={handleEstablishmentFilterChange}
    filterOptions={establishmentFilterOptions}
  >
    <div>
      <ResponsiveContainer height={200}>
        <PieChart>
          <Tooltip cursor={false} />
          <Pie
            animationBegin={0}
            data={establishmentData}
            dataKey={dataKey}
            nameKey="Establishment Name"
            cx="50%"
            cy="50%"
            innerRadius={35}
            outerRadius={70}
            label
          >
            {establishmentData.map((entry, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
      <div
        style={{
          position: "absolute",
          left: "0",
          top: "30px",
          width: "90%",
          pointerEvents: "none",
        }}
      >
        <div className={classes.giantNumberBgSmall}>
          <Typography className={classes.giantNumberSmall} />
        </div>
      </div>
    </div>
  </DashboardElement>
)

export default EstablishmentData
