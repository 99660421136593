import React from "react"
import { useApolloClient } from "@apollo/react-hooks"

import Helmet from "react-helmet"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Logo from "../assets/img/logo.png"
import { APOLLO_CONFIG } from "../apollo.services"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "30vw",
    maxWidth: 300,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    padding: theme.spacing(3),
  },
}))
const Forbidden = () => {
  const classes = useStyles()

  const client = useApolloClient()
  const logout = async () => {
    APOLLO_CONFIG.clearToken()
    await client.resetStore()
  }
  return (
    <>
      <Helmet>
        <style>
          {`
            body {
              background: #0080DD;
            }
            `}
        </style>
      </Helmet>

      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img src={Logo} className={classes.avatar} alt="LAPP" />
          <Card className={classes.card}>
            <Typography variant="h5">
              Ups, no tienes acceso a esta sección
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="secondary"
              className={classes.submit}
              onClick={logout}
            >
              Logout
            </Button>
          </Card>
        </div>
      </Container>
      <div></div>
    </>
  )
}

export default Forbidden
