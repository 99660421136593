import React, { useState } from "react"
import { useQuery } from "@apollo/react-hooks"
import gql from "graphql-tag"
import Loading from "../Loading"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"
import DashboardElement from "./components/DashboardElement"
import { makeStyles } from "@material-ui/core/styles"
import EstablishmentData from "./charts/EstablishmentData"
import GiantNumber from "./charts/GiantNumber"
import dashboardQuery from "./query"
import Table from "@material-ui/core/Table"
import TableHead from "@material-ui/core/TableHead"
import TableBody from "@material-ui/core/TableBody"
import TableRow from "@material-ui/core/TableRow"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"

import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts"
const useStyles = makeStyles(theme => ({
  giantNumber: {
    fontSize: "60px",
    fontWeight: "bold",
    lineHeight: "120px",
    textAlign: "center",
  },
  giantNumberBg: {
    margin: "20px auto",
    //backgroundColor:theme.palette.primary.main,
    backgroundColor: "#4DB6AC",
    borderRadius: "50%",
    marginTop: "40px",
    width: "120px",
    height: "120px",
  },
  giantNumberBg2: {
    margin: "20px auto",
    //backgroundColor:theme.palette.secondary.main,
    backgroundColor: "#FFD54F",
    color: "#212121",
    borderRadius: "50%",
    marginTop: "40px",
    width: "120px",
    height: "120px",
  },
  giantNumberSmall: {
    fontSize: "60px",
    color: "#212121",
    fontWeight: "bold",
    lineHeight: "100px",
    textAlign: "center",
  },
  giantNumberBgSmall: {
    margin: "20px auto",
    //backgroundColor:'#ffc402',
    borderRadius: "50%",
    width: "100px",
    height: "100px",
  },
}))
const Dashboard = ({ establishment = null, seatsEstablishments = [] }) => {
  const classes = useStyles()
  const [currentEstablishmentFilter, setCurrentEstablishmentFilter] = useState({
    data: "Students",
    title: "dashboard.establishmentStudents.title",
  })
  const {
    data: {
      dashboardEstablishment = [],
      dashboardStudentApprovedPerCourse = [],
      dashboardStudentPerCourse = [],
    } = {},
    loading: loadingDashboard,
  } = useQuery(dashboardQuery, {
    variables: {
      establishment,
    },
  })
  const { data: activeInactiveStudents, loading: loadingActive } = useQuery(
    gql`
      query($establishment: ID!) {
        activeInactiveStudents(where: { establishment: $establishment }) {
          active
          inactive
        }
      }
    `,
    {
      variables: {
        establishment,
      },
    },
  )
  const { data: { seats = [] } = {}, loadind: loadingSeats } = useQuery(gql`
    query {
      seats {
        amount
        establishment {
          id
          name
        }
        used
      }
    }
  `)

  const { data: dataDelayedUsers, loading: loadingUsers } = useQuery(
    gql`
      query($establishment: ID!) {
        delayedUsers(where: { establishment: $establishment })
      }
    `,
    {
      variables: {
        establishment,
      },
    },
  )
  const { data: dataApprovedUsers, loading: loadingApproved } = useQuery(
    gql`
      query($establishment: ID!) {
        approvedUsers(where: { establishment: $establishment })
      }
    `,
    {
      variables: {
        establishment,
      },
    },
  )
  const { data: dataSeatsByMonth, loading: loadingSeatsByMonth } = useQuery(
    gql`
      query($establishment: ID!) {
        seatsByMonth(where: { establishment: $establishment }) {
          payed
          notPayed
        }
      }
    `,
    {
      variables: {
        establishment,
      },
    },
  )
  if (
    loadingUsers ||
    loadingActive ||
    loadingApproved ||
    loadingDashboard ||
    loadingSeatsByMonth ||
    loadingSeats
  ) {
    return <Loading text={"Cargando informacion"} />
  }
  const compareDates = (a, b) => {
    var keyA = new Date(a.name),
      keyB = new Date(b.name)
    if (keyA < keyB) return -1
    if (keyA > keyB) return 1
    return 0
  }
  const timeApproved = Object.keys(dataApprovedUsers.approvedUsers)
    .map(val => {
      return {
        name: val,
        Basico: dataApprovedUsers.approvedUsers[val]["1"],
        Avanzado: dataApprovedUsers.approvedUsers[val]["2"],
      }
    })
    .sort(compareDates)
  const payedUsers = Object.keys(dataSeatsByMonth.seatsByMonth.payed)
    .map(val => {
      return {
        name: val,
        Basico: dataSeatsByMonth.seatsByMonth.payed[val]["1"],
        Avanzado: dataSeatsByMonth.seatsByMonth.payed[val]["2"],
      }
    })
    .sort(compareDates)
  const notPayedUsers = Object.keys(dataSeatsByMonth.seatsByMonth.notPayed)
    .map(val => {
      return {
        name: val,
        Basico: dataSeatsByMonth.seatsByMonth.notPayed[val]["1"],
        Avanzado: dataSeatsByMonth.seatsByMonth.notPayed[val]["2"],
      }
    })
    .sort(compareDates)
  const studentPerCourse = dashboardStudentPerCourse
  const studentApprovedPerCourse = dashboardStudentApprovedPerCourse
  const establishmentData = dashboardEstablishment.map(data => {
    return {
      "Done feedbacks": data.doneFeedbacks,
      Establishment: data.establishment,
      "Establishment Name": data.establishment.name,
      "Pending feedbacks": data.pendingFeedbacks,
      Students: data.students,
      "Total feedbacks": data.totalFeedbacks,
    }
  })

  const establishmentFilterOptions = [
    {
      name: "Alumnos",
      data: "Students",
      title: "Alumnos",
    },
    {
      name: "Evaluaciones totales",
      data: "Total feedbacks",
      title: "Evaluaciones totales",
    },
    {
      name: "Evaluaciones pendientes",
      data: "Pending feedbacks",
      title: "Evaluaciones pendientes",
    },
    {
      name: "Evaluaciones realizadas",
      data: "Done feedbacks",
      title: "Evaluaciones realizadas",
    },
  ]

  const colors = [
    "#9575CD",
    "#e57373",
    "#AED581",
    "#FFB74D",
    "#4DB6AC",
    "#64B5F6",
    "#90A4AE",
  ]

  const handleEstablishmentFilterChange = f => {
    setCurrentEstablishmentFilter(f)
  }
  const isSuperadmin = () => true
  return (
    <div>
      <Typography variant="h4" gutterBottom>
        {"Dashboard"}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={4} md={4} lg={4}>
          {isSuperadmin() && (
            <EstablishmentData
              establishmentData={establishmentData}
              establishmentFilterOptions={establishmentFilterOptions}
              colors={colors}
              classes={classes}
              title={currentEstablishmentFilter.title}
              handleEstablishmentFilterChange={handleEstablishmentFilterChange}
              dataKey={currentEstablishmentFilter.data}
            />
          )}
        </Grid>

        <Grid item xs={4} md={4} lg={4}>
          <DashboardElement title={"Alumnos por curso"} info={""}>
            <div>
              <ResponsiveContainer height={200}>
                <PieChart>
                  <Tooltip cursor={false} />
                  <Pie
                    animationBegin={0}
                    data={studentPerCourse}
                    dataKey={"students"}
                    nameKey="course"
                    cx="50%"
                    cy="50%"
                    innerRadius={35}
                    outerRadius={70}
                    label
                  >
                    {studentPerCourse.map((entry, index) => (
                      <Cell key={index} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </DashboardElement>
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <DashboardElement title={"Alumnos aprobados por curso"} info={""}>
            <div>
              <ResponsiveContainer height={200}>
                <PieChart>
                  <Tooltip cursor={false} />
                  <Pie
                    animationBegin={0}
                    data={studentApprovedPerCourse}
                    dataKey={"students"}
                    nameKey="course"
                    cx="50%"
                    cy="50%"
                    innerRadius={35}
                    outerRadius={70}
                    label
                  >
                    {studentApprovedPerCourse.map((entry, index) => (
                      <Cell key={index} fill={colors[index % colors.length]} />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          </DashboardElement>
        </Grid>

        <Grid item xs={4} md={4} lg={4}>
          <GiantNumber
            classes={classes}
            number={activeInactiveStudents.activeInactiveStudents.active}
            title={"Alumnos activos"}
            color="#f9f9f9"
            bgColor="#4DB6AC"
            data-testid="dashboard-total-users"
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <GiantNumber
            classes={classes}
            number={activeInactiveStudents.activeInactiveStudents.inactive}
            title={"Alumnos inactivos"}
            color="#f9f9f9"
            bgColor="#4DB6AC"
            data-testid="dashboard-total-users"
          />
        </Grid>
        <Grid item xs={4} md={4} lg={4}>
          <GiantNumber
            classes={classes}
            number={dataDelayedUsers.delayedUsers}
            title={"Alumnos en retraso"}
            color="#f9f9f9"
            bgColor="#4DB6AC"
            data-testid="dashboard-total-users"
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DashboardElement title={"Alumnos aprobados por mes"} info={""}>
            <LineChart
              width={800}
              height={300}
              data={timeApproved}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Basico"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="Avanzado" stroke="#82ca9d" />
            </LineChart>
          </DashboardElement>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DashboardElement title={"Alumnos Pagos por mes"} info={""}>
            <LineChart
              width={800}
              height={300}
              data={payedUsers}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Basico"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="Avanzado" stroke="#82ca9d" />
            </LineChart>
          </DashboardElement>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DashboardElement title={"Alumnos No Pagos por mes"} info={""}>
            <LineChart
              width={800}
              height={300}
              data={notPayedUsers}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line
                type="monotone"
                dataKey="Basico"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line type="monotone" dataKey="Avanzado" stroke="#82ca9d" />
            </LineChart>
          </DashboardElement>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <DashboardElement title={"Licencias por establecimiento"} info={""}>
            <TableContainer style={{ maxHeight: 640 }}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Establecimiento</TableCell>
                    <TableCell>Cantidad disponible</TableCell>
                    <TableCell>Cantidad usada</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {seats.map(seat => {
                    return (
                      <TableRow key={seat.establishment.id}>
                        <TableCell>{seat.establishment.name}</TableCell>
                        <TableCell>{seat.amount}</TableCell>

                        <TableCell>{seat.used}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DashboardElement>
          <DashboardElement title={"Licencias"} info={""}>
            <TableContainer style={{ maxHeight: 640 }}>
              <Table
                stickyHeader
                className={classes.table}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Basico</TableCell>
                    <TableCell>Basico Gratis</TableCell>
                    <TableCell>Basico Pago</TableCell>
                    <TableCell>Avanzado</TableCell>
                    <TableCell>Avanzado Gratis</TableCell>
                    <TableCell>Avanzado Pago</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {seatsEstablishments.map(seat => {
                    if (establishment && seat.establishment.id != establishment)
                      return null
                    return (
                      <TableRow key={seat.establishment.id}>
                        <TableCell>{seat.establishment.name}</TableCell>
                        <TableCell>{seat.count}</TableCell>
                        <TableCell>{seat.basic}</TableCell>
                        <TableCell>{seat.basicFree}</TableCell>
                        <TableCell>{seat.basicPurchased}</TableCell>
                        <TableCell>{seat.advanced}</TableCell>
                        <TableCell>{seat.advancedFree}</TableCell>
                        <TableCell>{seat.advancedPurchased}</TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DashboardElement>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
