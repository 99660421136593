import gql from "graphql-tag"

export default gql`
  query dashboard(
    $establishment: ID
  ) {
    viewerInfo {
      id
      role {
        id
        name
      }
    }
    dashboardStudentPerCourse(where: { establishmentId: $establishment }) {
      course
      students
    }

    dashboardStudentApprovedPerCourse(
      where: { establishmentId: $establishment }
    ) {
      course
      students
    }

    dashboardEstablishment(where: { establishmentId: $establishment }) {
      doneFeedbacks
      establishment {
        id
        name
      }
      pendingFeedbacks
      students
      totalFeedbacks
    }
  }
`
