import React, { useState } from "react"
import { useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"

import Helmet from "react-helmet"
import Card from "@material-ui/core/Card"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Logo from "../assets/img/logo.png"
import { useSnackbar } from "notistack"
import { APOLLO_CONFIG } from "../apollo.services"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    width: "30vw",
    maxWidth: 300,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  card: {
    padding: theme.spacing(3),
  },
}))

const VIEWER_INFO_QUERY = gql`
  query viewerInfo {
    viewerInfo {
      id
    }
  }
`
const useSignin = () => {
  const [signIn] = useMutation(
    gql`
      mutation signIn($email: String!, $password: String!) {
        signinUser(email: $email, password: $password) {
          token
          user {
            id
            email
            role {
              id
              name
            }
          }
        }
      }
    `,
    {
      update: (
        cache,
        {
          data: {
            signinUser: { token, user },
          },
        },
      ) => {
        cache.writeQuery({
          query: VIEWER_INFO_QUERY,
          data: { viewerInfo: user },
        })
        APOLLO_CONFIG.setToken(token)
      },
    },
  )
  return signIn
}
const Login = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const onChange = setter => e => setter(e.target.value)

  const snackbars = useSnackbar()
  const signIn = useSignin()
  const handleSubmit = async e => {
    e.preventDefault()
    if (email !== "" && password !== "" && !loading) {
      setLoading(true)
      try {
        await signIn({ variables: { email, password } })
      } catch (e) {
        setLoading(false)
        snackbars.enqueueSnackbar("Credenciales incorrectas", {
          variant: "error",
        })
      }
    }
  }
  const classes = useStyles()

  return (
    <>
      <Helmet>
        <style>
          {`
            body {
              background: #0080DD;
            }
            `}
        </style>
      </Helmet>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <img src={Logo} className={classes.avatar} alt="LAPP" />
          <Card className={classes.card}>
            <form className={classes.form} onSubmit={handleSubmit}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={onChange(setEmail)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={onChange(setPassword)}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                className={classes.submit}
                disabled={loading}
              >
                Sign In
              </Button>
            </form>
          </Card>
        </div>
      </Container>
    </>
  )
}

export default Login
